/* blog-part-section */

.blog-part-section {
  display: flex;
  align-items: center;
  margin: 0;
  margin-top: 3rem;
}

.blog-part-section__head {
  margin-bottom: 25px;
}

.blog-part-section h2 {
  margin: 0 0 24px 0;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  color: #1179bf;
  text-align: center;
}

.blog-part-section__lead {
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #3f3f3f;
}

.blog-part-section .container {
  padding: 0 160px;
}

@media (max-width: 1439px) {
  .blog-part-section .container {
    max-width: 1440px;
    padding: 0 11.11vw;
  }
}

@media (max-width: 767px) {
  .blog-part-section .container {
    padding: 0 24px;
  }
}

.blog-posts {
  display: flex;
  flex-wrap: wrap;
}

.blog-part-section .blog-post {
  padding: 0 8px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 65px;
}

.blog-part-section .blog-post:nth-child(1) {
  margin-top: 30px;
}

.blog-part-section .blog-post-image {
  width: 100%;
  height: 140px;
  background-size: cover;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  flex-shrink: 0;
  background-position: center;
}

.blog-part-section .blog-post:first-of-type .blog-post-image {
  width: 50%;
  margin-right: 40px;
  padding-bottom: 25%;
}

.blog-part-section .blog-post-data a:nth-child(1) {
  margin-top: 0.5rem;
}

.blog-part-section .blog-post-data a:nth-last-child(1) {
  margin-top: 16px;
}

.blog-part-section .blog-post:first-of-type .blog-post-data a {
  margin-top: 0;
}

.blog-part-section .blog-post:first-of-type {
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
}

.blog-part-section .blog-post {
  width: 25%;
}

.blog-part-section .blog-post h3 {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2rem;
  color: #3f3f3f;
  margin-top: 0.5rem;
}

.blog-part-section .blog-post:nth-child(1) h3 {
  font-size: 2rem;
  line-height: 2.5rem;
  margin-top: 0;
}

.blog-part-section .blog-post time {
  display: block;
  margin: 16px 0 0 0;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 1.8rem;
  color: #3f3f3f;
}

.blog-part-section .blog-post:nth-child(1) p {
  margin: 16px 0 0 0;
}

.blog-part-section .blog-post p {
  margin: 10px 0 0 0;
  font-size: 1.3rem;
  line-height: 1.8rem;
  color: #9b9b9b;
}

@media (max-width: 1200px) {
  .blog-part-section .blog-post:nth-child(1) h3 {
    font-size: 2.5rem;
    line-height: 3rem;
    margin-top: 0;
  }
}

@media (min-width: 959px) {
  .blog-post:not(:first-child) .blog-post-data {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    height: 350px;
  }
}

@media (max-width: 959px) {
  .blog-part-section .blog-posts {
    margin-top: 0;
  }

  .blog-part-section .blog-post {
    margin-top: 0;
    width: 50%;
    margin-bottom: 60px;
  }

  .blog-part-section .blog-post:nth-last-child(1),
  .blog-part-section .blog-post:nth-last-child(2) {
    margin-bottom: 0;
  }

  .blog-part-section .blog-post:first-of-type {
    flex-direction: column;
  }

  .blog-part-section .blog-post:first-of-type .blog-post-image {
    width: 100%;
    padding-bottom: 46.75%;
  }

  .blog-part-section .blog-post:first-of-type > .blog-post-data {
    margin-left: 0;
  }

  .blog-part-section .blog-post:first-of-type .blog-post-image {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

@media (max-width: 413px) {
  .blog-part-section .blog-post {
    width: 100%;
  }

  .blog-part-section .blog-post:first-of-type .blog-post-image {
    margin-bottom: 0;
    padding-bottom: 100%;
  }

  .blog-part-section .blog-post:first-child h3 {
    font-size: 24px;
    line-height: 29px;
  }

  .blog-part-section .blog-post:first-child p {
    margin-top: 10px;
  }

  .blog-part-section .blog-post:nth-last-child(2) {
    margin-bottom: 60px;
  }

  .blog-part-section .blog-post:nth-last-child(1) {
    margin-bottom: 0;
  }
}

.blog-post-data {
  margin-bottom: auto;
}

.blog-post a {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #f9ae3b;
}

@media (min-width: 959px) and (max-width: 1200px) {
  .blog-post:not(:first-child) a {
    height: 45%;
  }

  .blog-part-section .blog-post:not(:first-child) p:nth-child(3) {
    height: 30%;
  }
}

@media (min-width: 1100px) {
  .blog-post:not(:first-child) a {
    height: 35%;
  }

  .blog-part-section .blog-post:not(:first-child) p:nth-child(3) {
    height: 25%;
  }
}

@media (min-width: 1440px) {
  .blog-post:not(:first-child) a {
    height: 40%;
  }

  .blog-part-section .blog-post:not(:first-child) p:nth-child(3) {
    height: 30%;
  }
}

.blog-read-more-arrow {
  background-image: url(../images/blog/read-more-arrow.svg);
  background-size: 100% 100%;
  width: 1rem;
  display: inline-block !important;
  height: 1rem;
  margin-top: -2px !important;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.blog-categories {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background: #f6f6f6;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
}

.blog-categories__item {
  padding: 12px 15px;
}

.blog-categories__link {
  width: 100%;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #5f5f5f;
}

.faq .blog-categories__link {
  min-width: 375px;
}

@media (max-width: 599px) {
  .faq .blog-categories__link {
    min-width: auto;
  }
}

@media (max-width: 1439px) {
  .blog-categories__link {
    width: 100%;
  }
}

.blog-categories__link::before {
  content: '';
  margin-right: 10px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #ced5e0;
}

.blog-categories__link--active {
  background-color: #ffffff;
  border-radius: 7px;
}

.blog-categories__link--active::before {
  background-color: #f1c071;
  background-image: url(../images/blog/active-category.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (max-width: 1439px) {
  .blog-categories {
    flex-direction: column;
    max-width: 500px;
    margin: 0 auto;
  }

  .blog-categories__item {
    padding: 15px 20px;
  }

  .blog-categories__link::before {
    left: 20px;
  }
}
